import { createSlice } from "@reduxjs/toolkit";

export const organizationSlice: any = createSlice({
  name: "organizationSlice",
  initialState: {
    tab: "1",
  },
  reducers: {
    setTab: (state, action) => {
      state.tab = action.payload;
    },
  },
});
export const { setTab } = organizationSlice.actions;
export default organizationSlice.reducer;
