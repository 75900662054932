import {
  getProvincesSuccess,
} from "features/provinceSlice";
import { call, put, takeLatest } from "redux-saga/effects";
import { GET } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
import _ from "lodash";
export function* getProvinces(data: ActionType): any {
  const { callback, body, query } = data.payload;
  const url = `${ServiceURL.province}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res?.code && !!res?.message) {
      callback.failed(res?.message);
    } else {
      callback.success(_.get(res, "data", ""));
      yield put(getProvincesSuccess(res));
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}
export default function* provinceSaga() {
  yield takeLatest("provinceSlice/getProvinces", getProvinces);
}
