import { createSlice } from "@reduxjs/toolkit";

export const awardSlice: any = createSlice({
  name: "awardSlice",
  initialState: {
    awards: [],
    award: {},
    queryAward: {},
  },
  reducers: {
    getAwards: (state, action) => {},
    getAwardsSuccess: (state, action) => {
      state.award = action.payload;
    },
    getAward: (state, action) => {},
  },
});
export const {
  getAwards,
  getAwardsSuccess,
  getAward,
} = awardSlice.actions;
export default awardSlice.reducer;
