import { createSlice } from "@reduxjs/toolkit";

export const provinceSlice: any = createSlice({
  name: "provinceSlice",
  initialState: {
    provinces: [],
    province: {},
    queryProvince: {},
  },
  reducers: {
    getProvinces: (state, action) => {},
    getProvincesSuccess: (state, action) => {
      state.province = action.payload;
    },
    getProvince: (state, action) => {},
  },
});
export const {
  getProvinces,
  getProvincesSuccess,
  getProvince,
} = provinceSlice.actions;
export default provinceSlice.reducer;
