import "antd/dist/antd.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import AuthLayout from "layouts/Auth";
import { Provider } from "react-redux";
import "./index.css";
import store from "store";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Admin from "layouts/Admin";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path="/admin" render={(props) => <Admin />} />
        <Route path="/auth" render={(props) => <AuthLayout />} />
        <Redirect from="*" to="/auth/login" />
      </Switch>
    </BrowserRouter>
  </Provider>
);
