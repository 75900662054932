import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const classroomSlice: any = createSlice({
  name: "classroomSlice",
  initialState: {
    classrooms: [],
    query: {
      page: 1,
      limit: 10,
      year: moment().get("year"),
      populate: "schoolId,teacherId",
    },
    classroom: {},
    filters: null,
  },
  reducers: {
    getClassrooms: (state, action) => {},
    getClassroom: (state, action) => {},
    getClassroomsSuccess: (state, action) => {
      state.classrooms = action.payload;
    },
    getClassroomSuccess: (state, action) => {
      state.classroom = action.payload;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setFilter: (state, action) => {
      state.filters = action.payload;
    },
    setStateClassroom: (state: any, action) => {
      Object.keys(action.payload).map((key) => {
        state[`${key}`] = action.payload[`${key}`];
      });
    },
    createClassroom: (state, action) => {},
    updateClassroom: (state, action) => {},
    deleteClassroom: (state, action) => {},
  },
});
export const {
  getClassrooms,
  getClassroom,
  updateClassroom,
  getClassroomsSuccess,
  getClassroomSuccess,
  setQuery,
  setFilter,
  createClassroom,
  deleteClassroom,
  setStateClassroom,
} = classroomSlice.actions;
export default classroomSlice.reducer;
