import { createSlice } from "@reduxjs/toolkit";

export const districtSlice: any = createSlice({
  name: "districtSlice",
  initialState: {
    districts: [],
    district: {},
    queryDistrict: {},
  },
  reducers: {
    getDistricts: (state, action) => {},
    getDistrictsSuccess: (state, action) => {
      state.district = action.payload;
    },

    getDistrict: (state, action) => {},
  },
});
export const {
  getDistricts,
  getDistrictsSuccess,
  getDistrict,
} = districtSlice.actions;
export default districtSlice.reducer;
