import { notification } from "antd";
import { getPaymentSuccess, getPaymentsSuccess } from "features/paymentSlice";
import queryString from "query-string";
import { call, all, put, takeLatest } from "redux-saga/effects";
import { getClassrooms } from "sagas/classroomSaga";
import { getRoles } from "sagas/roleSaga";
import { DELETE, GET, POST, PUT } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
var _ = require("lodash");
export function* getPayments(data: ActionType): any {
  const { callback, body, query, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.payment}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      if (!skipUpdateReducer)
        yield put(getPaymentsSuccess(_.get(res, "data", [])));
      callback.success(_.get(res, "data", []));
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}
export function* getPayment(data: ActionType): any {
  const { callback, body, query, params } = data.payload;
  const url = `${ServiceURL.payment}/${params}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      yield put(getPaymentSuccess(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* createPayment(data: ActionType): any {
  const { callback, body } = data.payload;
  const url = ServiceURL.payment;
  try {
    const res = yield call(POST, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* updatePayment(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.payment}/${params}`;
  try {
    const res = yield call(PUT, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* deletePayment(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.payment}/${params}`;
  try {
    const res = yield call(DELETE, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success(res);
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export default function* paymentSaga() {
  yield takeLatest("paymentSlice/getPayments", getPayments);
  yield takeLatest("paymentSlice/getPayment", getPayment);
  yield takeLatest("paymentSlice/createPayment", createPayment);
  yield takeLatest("paymentSlice/updatePayment", updatePayment);
  yield takeLatest("paymentSlice/deletePayment", deletePayment);
}
